import { createApi } from "@reduxjs/toolkit/query/react";
import { current } from "@reduxjs/toolkit";
import {
  UpdateEmployerStatus,
  CreateEmployer,
  CreateEmployerData,
  EmployerInfo,
  EmployerList,
  GetAllEmployerInfo,
  SearchParam,
  UpdateEmployerData,
  ViewEmployerData,
  FileUpload,
  UploadFile,
  RemoveFileData,
  RemoveFile,
  DeleteEmployerResponse,
  DeleteEmployerQuery,
  ChangeStatus,
  OrgEmployeeList,
  DownloadEmpFile,
  EmployerType,
  EmployerConfig,
  ViewEmployerResponse,
  EmployerTypes,
} from "../../types/employer";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const EMPLOYER_API_REDUCER_KEY = "employerApi";

export const employerApi = createApi({
  reducerPath: EMPLOYER_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Employers"],
  endpoints: (builder) => ({
    getEmployerList: builder.query<EmployerList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.EMPLOYER_LISTING,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Employers"],
    }),
    getActiveEmployerList: builder.query<EmployerList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { searchText = "" } = args;
        return {
          url: ApiConstant.ACTIVE_EMPLOYER_LISTING,
          method: "POST",
          body: { searchText },
        };
      },
      providesTags: ["Employers"],
    }),
    getEmployerById: builder.query<ViewEmployerData, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.EMPLOYER_BY_ID}/${id}`,
          method: "GET",
        };
      },
    }),
    addEmployer: builder.mutation<CreateEmployerData, Partial<CreateEmployer>>({
      query(body) {
        return {
          url: ApiConstant.ADD_EMPLOYER,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Employers"],
    }),
    completeEmployer: builder.mutation<
      UpdateEmployerData,
      Partial<EmployerInfo> & Pick<GetAllEmployerInfo, "ID">
    >({
      query({ ID, ...body }) {
        const url = ID
          ? `${ApiConstant.SUBMIT_EMPLOYER}/${ID}`
          : `${ApiConstant.SUBMIT_EMPLOYER}`;
        return {
          url,
          method: "PUT",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employers"],
    }),
    editEmployer: builder.mutation<
      UpdateEmployerData,
      Partial<EmployerInfo> & Pick<GetAllEmployerInfo, "ID">
    >({
      query({ ID, ...body }) {
        return {
          url: `${ApiConstant.CREATE_EMPLOYER}/${ID}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employers"],
    }),
    fileUploader: builder.mutation<FileUpload, UploadFile>({
      query(body) {
        return {
          url: `employer/fileUpload`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employers"],
    }),
    removeFile: builder.mutation<RemoveFileData, RemoveFile>({
      query(body) {
        return {
          url: `employer/deleteFile`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employers"],
    }),
    changeEmployerStatus: builder.mutation<UpdateEmployerStatus, ChangeStatus>({
      query: (body: ChangeStatus) => {
        const { ID, status } = body;
        return {
          url: ApiConstant.CHANGE_EMPLOYER_STATUS,
          method: "POST",
          body: { ID, status },
        };
      },
      // invalidatesTags: ['Employers'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, status, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            employerApi.util.updateQueryData(
              "getEmployerList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.ID = updatedInfo.data.ID;
                  info.Status =
                    updatedInfo.data.Status === "inactive" ? "3" : "2";
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    deleteEmployer: builder.mutation<
      DeleteEmployerResponse,
      DeleteEmployerQuery
    >({
      query: (args: DeleteEmployerQuery) => {
        const { ID } = args;
        return {
          url: ApiConstant.DELETE_EMPLOYER(ID),
          method: "DELETE",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            employerApi.util.updateQueryData(
              "getEmployerList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === ID
                );
                if (objIndex !== -1) {
                  draft.data.splice(objIndex, 1);
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    getOrgEmployeeList: builder.query<OrgEmployeeList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const {
          pageNo = 1,
          size = 30,
          searchText = "",
          EmployerID = "",
        } = args;
        const body = { pageNo, size, EmployerID };
        return {
          url: ApiConstant.ORG_EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body, searchText },
        };
      },
    }),
    getEmployeeBulkFile: builder.query<DownloadEmpFile, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.DOWNLOAD_EMPLOYEE_FILE}/${id}`,
          method: "GET",
        };
      },
    }),
    getEmployerType: builder.query<EmployerType, {}>({
      query: () => {
        return {
          url:  ApiConstant.ORGANISATION_TYPE,
          method: "GET",
          headers: {
            'apikey': "a5274744-f550-409a-95a1-2d3b8f9fe803",
            'Content-Type': 'application/json', 
          },
        };
      },
    }),
    getEmployerConfig: builder.query<EmployerConfig, string>({
      query: (id: string) => {
        return {
          url:  `${ApiConstant.TYPE_CONFIG}/${id}`,
          method: "GET",
        };
      },
    }),
    getOrganisationType:  builder.query<EmployerTypes, string>({
      query: (id: string) => {
        return {
          url:  `${ApiConstant.ORG_TYPE}/${id}`,
          method: "GET",
        };
      },
    }),
    getEmployerListByType: builder.query<ViewEmployerResponse, string>({
      query: (orgtypeid: string) => {
        return {
          url:  `${ApiConstant.EMPLOYER}/${orgtypeid}`,
          method: "GET",
          headers: {
            'apikey': "a5274744-f550-409a-95a1-2d3b8f9fe803",
            'Content-Type': 'application/json', 
          },
        };
      },
    }),       
  }),
});

// query: (args: DeleteEmployerQuery) => {
//   const { ID } = args;
//   return {
//     url: ApiConstant.DELETE_EMPLOYER(ID),
//     method: "DELETE",
//   };
// },
export const {
  useGetEmployerListQuery,
  useGetEmployerByIdQuery,
  useAddEmployerMutation,
  useEditEmployerMutation,
  useChangeEmployerStatusMutation,
  useFileUploaderMutation,
  useRemoveFileMutation,
  useDeleteEmployerMutation,
  useCompleteEmployerMutation,
  useGetActiveEmployerListQuery,
  useGetOrgEmployeeListQuery,
  useGetEmployeeBulkFileQuery,
  useLazyGetEmployeeBulkFileQuery,
  useGetEmployerTypeQuery,
  useGetEmployerConfigQuery,
  useGetOrganisationTypeQuery,
  useGetEmployerListByTypeQuery,
} = employerApi;
