import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  SearchParam,
  AllImsWebinarInfo,
  CreateImsWebminarData,
  EmployerInfoList,
  GetWebinarInfo,
  ImsWebminarList,
  UpdateImsWebminarData,
  AddAttendeesInfo,
  AddAttendeesData,
  EmployerList,
  UpdateWebinarStatus,
  UpdateWebinarInfo,
  GetWebinarCancelInfo,
  // attendeesListInfo,
  DownloadAttendeesCSV,
  DownloadCSVQuery,
  AddOrgQueryWithSeminar,
  getAttendeesListInfo,
} from "types/imsWebinar";
import { current } from "@reduxjs/toolkit";
import { RuleListResponse, SearchParam as QueryParam } from "types/ruleMaster";

export const ADMIN_API_REDUCER_KEY = "imsWebminarApi";

export const imsWebminarApi = createApi({
  reducerPath: ADMIN_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["ImsWebinar"],
  endpoints: (builder) => ({
    getWebinarList: builder.query<ImsWebminarList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, field, searchText = "", filter } = args;
        const body = { pageNo, size, field };
        return {
          url: ApiConstant.IMS_WEBINAR_LISTING,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["ImsWebinar"],
    }),
    getWebinarById: builder.query<GetWebinarInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.WEBINAR_BY_ID}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["ImsWebinar"],
    }),
    getDuplicateWebinarById: builder.query<GetWebinarInfo, string>({
      query: (duplicateID: string) => {
        return {
          url: `${ApiConstant.DUPLICATE_WEBINAR_BY_ID}/${duplicateID}`,
          method: "GET",
        };
      },
    }),
    createImsWebminar: builder.mutation<
      CreateImsWebminarData,
      Partial<AllImsWebinarInfo>
    >({
      query(body) {
        return {
          url: ApiConstant.CREATE_IMS_WEBINAR,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["ImsWebinar"],
    }),
    getEmployerByQuery: builder.query<EmployerInfoList, AddOrgQueryWithSeminar>({
      query: (body) => {
        return {
          url: ApiConstant.GET_EMPLOYER_BY_QUERY,
          method: "POST",
          body
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    editWebinar: builder.mutation<
      UpdateImsWebminarData,
      Partial<AllImsWebinarInfo> & Pick<AllImsWebinarInfo, "ID">
    >({
      query(body) {
        const { ID, ...rest } = body;
        return {
          url: ApiConstant.UPDATE_IMS_WEBINAR(ID),
          method: "PATCH",
          body: rest,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["ImsWebinar"],
    }),
    addAttendees: builder.mutation<AddAttendeesData, Partial<AddAttendeesInfo>>(
      {
        query(body) {
          return {
            url: ApiConstant.ADD_ATTENDEES,
            method: "POST",
            body,
          };
        },
        transformErrorResponse: (response: {
          data: any;
          status: number;
        }): string => response.data.message,
        invalidatesTags: ["ImsWebinar"],
      }
    ),
    getActiveEmployerList: builder.query<EmployerList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { searchText = "" } = args;
        return {
          url: ApiConstant.ACTIVE_EMPLOYER_LISTING,
          method: "POST",
          body: { searchText },
        };
      },
      providesTags: ["ImsWebinar"],
    }),
    changeWebinarStatus: builder.mutation<UpdateWebinarStatus, string>({
      query: (body) => {
        return {
          url: ApiConstant.CHANGE_WEBINAR_STATUS,
          method: "POST",
          body,
        };
      },
      // eslint-disable-next-line no-empty-pattern
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            imsWebminarApi.util.updateQueryData(
              "getWebinarList",
              { pageNo: 1, size: 30, searchText: "", filter: [] },
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );

                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.Enabled = updatedInfo.data.Enabled;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    getAttendeesList: builder.query<getAttendeesListInfo, Partial<SearchParam>>(
      {
        query: (args: SearchParam) => {
          const { webinarId = "", pageNo = 1, size = 30 } = args;
          const body = { pageNo, size, webinarId };
          return {
            url: ApiConstant.GET_ATTENDEES_LISTING,
            method: "POST",
            body: body,
          };
        },
        providesTags: ["ImsWebinar"],
      }
    ),
    downloadAttendeesCSV: builder.query<DownloadAttendeesCSV, DownloadCSVQuery>(
      {
        query(body) {
          return {
            url: ApiConstant.DOWNLOAD_ATTENDEES,
            method: "POST",
            body,
          };
        },
      }
    ),
    cancelBookingWebinar: builder.mutation<
      UpdateWebinarInfo,
      Partial<GetWebinarCancelInfo>
    >({
      query({ ID, ...body }) {
        return {
          url: `${ApiConstant.CANCEL_WEBINAR}/${ID}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["ImsWebinar"],
    }),
    getActiveRuleList: builder.query<RuleListResponse, QueryParam>({
      query: (args: SearchParam) => {
          return {
              url: ApiConstant.RULE_LIST,
              method: "POST",
              body: args
          }
      }
    }),
  }),
});

export const {
  useGetWebinarListQuery,
  useGetWebinarByIdQuery,
  useGetDuplicateWebinarByIdQuery,
  useCreateImsWebminarMutation,
  useEditWebinarMutation,
  useGetEmployerByQueryQuery,
  useLazyGetEmployerByQueryQuery,
  useAddAttendeesMutation,
  useGetActiveEmployerListQuery,
  useChangeWebinarStatusMutation,
  useCancelBookingWebinarMutation,
  useGetAttendeesListQuery,
  useLazyDownloadAttendeesCSVQuery,
  useGetActiveRuleListQuery
} = imsWebminarApi;
