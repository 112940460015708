import { createSlice } from "@reduxjs/toolkit";

// third-party

// project import
import { FilterType } from "../../types/filter";
import { SearchParam } from "types/changelog";
// types

const initialState: FilterType = {
  admin: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  employer: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  employee: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  schemeType: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  provider: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  investmentOption: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  pensionFund: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  supplier: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
    sortColumn: "",
    sortOrder: "",
  },
  avcPlan: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  privatePlan: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  pensionType: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  payPeriod: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
    sortColumn: "DESC",
    sortOrder: "CreatedAt",
  },
  scheme: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
    sortColumn: "DESC",
    sortOrder: "CreatedAt",
  },
  imsWebinar: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  seminar: {
    pageNo: 1,
    size: 30,
    searchText: "",
    filter: [],
  },
  auditLog: {
    products: {
      size: 10,
      pageNo: 1,
      item: "Products"
    },
    employers: {
      size: 10,
      pageNo: 1,
      item: "Employers"
    },
    employees: {
      size: 10,
      pageNo: 1,
      item: "Employees"
    },
    schemeTypes: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    providers: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    investmentOptions: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    pensionFunds: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    pensionTypes: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    payPeriod: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    suppliers: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    aVCPlans: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    schemes: {
      size: 10,
      pageNo: 1,
      item: "MasterDataEntity"
    },
    journey: {
      item: "FinancialHealthCheck",
      pageNo: 1,
      size: 10
    },
    consent: {
      item: "FinancialHealthCheck",
      pageNo: 1,
      size: 10
    },
    responses: {
      item: "FinancialHealthCheck",
      pageNo: 1,
      size: 10
    },
    smartEngineProducts: {
      item: "FinancialHealthCheck",
      pageNo: 1,
      size: 10
    }
  },
  journey: {
    pageNo: 1,
    size: 10
  },
  consent: {
    pageNo: 1,
    size: 30,
    sortColumn: "FirstName",
    sortOrder: "ASC",
  },
  smartEngineProducts: {
    pageNo: 1,
    size: 30,
    sortColumn: "FirstName",
    sortOrder: "ASC",
  },
};

// ==============================|| filter - SLICE ||============================== //

const filter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setAdminFilterState(state, action) {
      state.admin = action.payload;
    },
    setEmployerFilterState(state, action) {
      state.employer = action.payload;
    },
    setEmployeeFilterState(state, action) {
      state.employee = action.payload;
    },
    setSchemeTypeFilterState(state, action) {
      state.schemeType = action.payload;
    },
    setProviderFilterState(state, action) {
      state.provider = action.payload;
    },
    setInvestmentOptionFilterState(state, action) {
      state.investmentOption = action.payload;
    },
    setPensionFundFilterState(state, action) {
      state.pensionFund = action.payload;
    },
    setSupplierFilterState(state, action) {
      state.supplier = action.payload;
    },
    setAvcPlanFilterState(state, action) {
      state.avcPlan = action.payload;
    },
    setPrivatePlanFilterState(state, action) {
      state.privatePlan = action.payload;
    },
    setPensionTypeFilterState(state, action) {
      state.pensionType = action.payload;
    },
    setAuditLogFilterState(state, action) {
      Object.keys(state.auditLog).forEach((key) => {
        const k = key as keyof SearchParam;
        const filterState = state.auditLog[k].item === action.payload.item
        if (filterState) {
          state.auditLog[k] = action.payload
        } else {
          state.auditLog[k] = initialState.auditLog[k]
        }
      });
    },

    setImsFilterState(state, action) {
      state.imsWebinar = action.payload;
    },

    setSeminarFilterState(state, action) {
      state.seminar = action.payload;
    },

    setPayPeriodFilterState(state, action) {
      state.payPeriod = action.payload;
    },
    setSchemeFilterState(state, action) {
      state.scheme = action.payload;
    },
    setJourneyFilterState(state, action) {
      state.journey = action.payload;
    },
    setConsentFilterState(state, action) {
      state.consent = action.payload;
    },
    setSmartEngineProductFilterState(state, action) {
      state.smartEngineProducts = action.payload
    },
    clearAllFilterState(state, action) {
      Object.keys(initialState).forEach((key) => {
        const k = key as keyof FilterType;
        if (k !== action.payload) {
          state[k] = initialState[k] as any;
        }
      });
    },
  },
});

export default filter.reducer;

export const {
  setAdminFilterState,
  setEmployerFilterState,
  setEmployeeFilterState,
  setSchemeTypeFilterState,
  setProviderFilterState,
  setInvestmentOptionFilterState,
  setPensionFundFilterState,
  setSupplierFilterState,
  setAvcPlanFilterState,
  setPensionTypeFilterState,
  setPayPeriodFilterState,
  clearAllFilterState,
  setSchemeFilterState,
  setAuditLogFilterState,
  setImsFilterState,
  setJourneyFilterState,
  setConsentFilterState
} = filter.actions;
