import MainCard from "components/MainCard";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Grid,
  Stack,
  Button,
  InputLabel,
  Typography,
  FormControl
} from "@mui/material";
import * as yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  smartEngineProduct,
} from "types/smart-engine-product";
import {
  useGetAllActiveProductsQuery,
} from "store/reducers/smartEngineProduct";
import { Column } from "react-table";
import { useModal } from "mui-modal-provider";
import Avatar from "components/@extended/Avatar";
import { CloseCircleFilled } from "@ant-design/icons";
import CustomDraggableReactTable from "components/CustomDraggableReactTable";
import { useNavigate } from "react-router";
import FormField from "components/formField";
import { useGetActiveRuleListQuery } from "store/reducers/ruleMaster";
import { LoadingButton } from "@mui/lab";
import isEmpty from 'lodash/isEmpty'

type RowInfo = {
  row: any;
};

type Product = { id: string; ProductName: string; sequence: number };

type SequenceFormProps = {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  info?: smartEngineProduct;
  isSubmitting: boolean;
};

const validationSchema = yup.object({
  productSequence: yup
    .array()
    .of(
      yup.string().required()
    )
})

const ProductNames = ["Pension Top-Up", "NHS", "AVCWISE", "Nudge"]

function SequenceForm({
  onCancel,
  onSubmit,
  info,
  isSubmitting,
}: SequenceFormProps) {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [ProductListString, setProductListString] = useState("");
  const [searchText, setSearchText] = useState("")

  const { data: Products, isLoading: isLoadingProducts } =
    useGetAllActiveProductsQuery("", {
      refetchOnMountOrArgChange: true,
    });

  const { data: rulesInfo, isFetching: isFetchingRules } =
    useGetActiveRuleListQuery({ searchText: searchText, applicableFor: "product" }, { refetchOnMountOrArgChange: true });

  const arrProductsData = useMemo(() => {
    const arr = Products?.data.filter((item) => !ProductNames.includes(item.ProductName)) ?? []
    return arr
  }, [Products]);
  
  const arrExcludedProductsID = useMemo(() => {
    const arr = Products?.data.filter((item) => ProductNames.includes(item.ProductName)).map((item) => item.ID) ?? []
    return arr
  }, [Products]);

  const arrWebinarRules = useMemo(() => {
    const arr = rulesInfo?.data.rules ?? []
    return arr?.map((item) => ({
      value: item.ID,
      label: item.Title
    })) ?? []
  }, [rulesInfo])

  const initialValues = {
    productSequence: [],
    ruleID: info?.RuleID ?? ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const obj = info
      const valuesInfo = structuredClone(values)
      const valuesData = { ...valuesInfo, ...{ productSequence: [...valuesInfo.productSequence, ...arrExcludedProductsID] } } 
      const val = isEmpty(obj) ? valuesData : { ...valuesData, ...{ ID: obj.ID } }
      onSubmit(val)
    },
  });

  const { values, setFieldValue, setFieldTouched, handleSubmit } =
    formik;

  const [productsList, setproductsList] = useState<Product[]>([]);

  function sortArrayByIdList(dataArr: any[], idList: string[]): any[] {
    const idToObjectMap = new Map(
      dataArr.map((obj: { ID: any }) => [obj.ID, obj])
    );

    return idList.map((id: string) => idToObjectMap.get(id)).filter(Boolean);
  }

  useEffect(() => {
    let data = arrProductsData || [];

    if (info && data.length > 0) {
      const sortedArr = sortArrayByIdList(data, info.ProductSequence);
      data = sortedArr;
    }

    const list: { id: string; ProductName: string; sequence: number }[] =
      data.map((pr: { ID: any; ProductName: any }, index: number) => ({
        id: pr?.ID,
        ProductName: pr?.ProductName,
        sequence: index + 1,
      })) || [];

    setproductsList(list);
  }, [arrProductsData, info, setFieldValue]);


  useEffect(() => {
    let list = "";

    for (let a = 0; a < productsList.length; a++) {
      const p = productsList[a];

      list =
        list +
        (a < productsList.length - 1
          ? `${p.ProductName}, `
          : `${p.ProductName}`);
    }

    setProductListString(list);

    let l = [];

    for (let a = 0; a < productsList.length; a++) {
      const p = productsList[a];

      l.push(p.id);
    }

    setFieldValue("productSequence", l)


  }, [productsList, info, setFieldValue]);

  const SequenceColumn = useCallback((props: RowInfo) => {
    const { row } = props;
    const { index } = row;

    return <Typography>{index + 1}</Typography>;
  }, []);

  const sequenceUpdateColumns: Column[] = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Sequence",
        Cell: SequenceColumn,
      },
    ],
    [SequenceColumn]
  );

  const openModal = useCallback(() => {
    const modal = showModal(CustomDraggableReactTable, {
      title: "Define Product Display Sequence",
      closeButton: (
        <Avatar
          onClick={() => {
            modal.hide();
          }}
        >
          <CloseCircleFilled />
        </Avatar>
      ),
      dragFun: (data: any[]) => {
        modal.update({
          data: data,
        });
      },
      data: productsList,
      isFetching: isLoadingProducts,
      onUpdate: (data: any[]) => {
        setproductsList(data);

        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      sequenceUpdateColumns,
    });
  }, [isLoadingProducts, productsList, sequenceUpdateColumns, showModal]);


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <MainCard>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Name">
                  Product Display Sequence
                  <Typography component="span" color="#ff0000">
                    *
                  </Typography>
                </InputLabel>
                <FormControl fullWidth>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={openModal}
                    style={{
                      minHeight: 40,
                    }}
                  >
                    {ProductListString}
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Display Rule"
                control="select-async"
                onBlur={() => setFieldTouched("ruleID", !values.ruleID)}
                name="Rule"
                id="Rule"
                loading={isFetchingRules}
                options={arrWebinarRules}
                onInputChange={(event, value) => {
                  setSearchText(value)
                }}
                value={
                  arrWebinarRules.find(
                    (option) => option.value === values.ruleID
                  ) ?? null
                }
                onChange={(value) => {
                  console.log("Value Rule Id", value)
                  setFieldValue("ruleID", value?.value ?? "")
                }
                }
              />
            </Grid>
          </Grid>
        </MainCard>

        <Grid container className="position-fixed" sx={{ mt: "10px" }}>
          <Grid item xs={12} sx={{ p: "10px 20px 20px 20px" }}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting}
                sx={{ mr: "10px" }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default SequenceForm;
