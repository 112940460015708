import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CreateScheme,
  UpdateScheme,
  UpdateSchemeResponse,
  SchemeById,
  CommentInfo,
  AddComment,
  DownloadCSVResponse,
  CreateSchemeResponse,
  ActivePensionList,
  ActiveSchemeTypeList,
  ActiveEmployerList,
  SearchParam,
  SchemeListResponse,
  SchemeStatus,
  SchemeStatusResponse,
  EmployerSchemeResponse
} from "../../types/scheme";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const SCHEME_API_REDUCER_KEY = "schemeApi";

export const schemeApi = createApi({
  reducerPath: SCHEME_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Schemes"],
  endpoints: (builder) => ({
    getSchemeList: builder.query<SchemeListResponse, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const {
          pageNo = 1,
          size = 30,
          searchText = "",
          sortColumn,
          sortOrder,
          filter,
        } = args;
        const body = { pageNo, size, sortColumn, sortOrder };
        return {
          url: ApiConstant.SCHEME_LIST,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Schemes"],
    }),
    createScheme: builder.mutation<CreateSchemeResponse, Partial<CreateScheme>>(
      {
        query(body) {
          return {
            url: ApiConstant.CREATE_SCHEME,
            method: "POST",
            body,
          };
        },
        transformErrorResponse: (response: {
          data: { error: string; message: string };
          status: number;
        }): string => response.data.error,
        invalidatesTags: ["Schemes"],
      }
    ),
    updateScheme: builder.mutation<UpdateSchemeResponse, UpdateScheme>({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_SCHEME(ID),
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Schemes"],
    }),
    getActiveSchemeTypes: builder.query<ActiveSchemeTypeList, string>({
      query: (ID: string) => {
        return {
          url: ApiConstant.GET_ALL_SCHEME_TYPES(ID),
          method: "GET",
        };
      },
    }),
    getAssociatedSchemeForEmployer: builder.query<EmployerSchemeResponse, string>({
      query: (employerId: string) => {
        return {
          url: ApiConstant.SCHEME_BY_EMPLOYER_ID(employerId),
          method: "GET",
        };
      },
    }),
    getActivePensionTypes: builder.query<ActivePensionList, string>({
      query: (id: string) => {
        return {
          url: ApiConstant.GET_ACTIVE_PENSION_TYPES(id),
          method: "GET",
        };
      },
    }),
    getActiveEmployerList: builder.mutation<ActiveEmployerList, string>({
      query: (searchText: string) => {
        return {
          url: ApiConstant.GET_ACTIVE_EMPLOYER_LIST,
          method: "POST",
          body: { searchText: searchText },
        };
      },
    }),
    getSchemeById: builder.query<SchemeById, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.SCHEME_DETAIL_BY_ID(id)}`,
          method: "GET",
        };
      },
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    changeSchemeStatus: builder.mutation<SchemeStatusResponse, SchemeStatus>({
      query: (body: SchemeStatus) => {
        const { ID, EmployerID, IsActive, force } = body;
        return {
          url: ApiConstant.CHANGE_SCHEME_STATUS(ID),
          method: "POST",
          body: { EmployerID, IsActive, force },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, EmployerID, IsActive, force, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            schemeApi.util.updateQueryData(
              "getSchemeList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.IsActive = updatedInfo.data.IsActive;
                  draft.data[objIndex] = info;

                  draft.data.forEach((res) => {
                    if (
                      res.EmployerID === updatedInfo.data.EmployerID &&
                      res.ID !== updatedInfo.data.ID
                    ) {
                      res.IsActive = false;
                    }
                  });
                }
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetSchemeListQuery,
  useCreateSchemeMutation,
  useUpdateSchemeMutation,
  useGetSchemeByIdQuery,
  useAddCommentMutation,
  useLazyDownloadCSVQuery,
  useGetActivePensionTypesQuery,
  useGetActiveSchemeTypesQuery,
  useLazyGetSchemeByIdQuery,
  useGetActiveEmployerListMutation,
  useChangeSchemeStatusMutation,
  useGetAssociatedSchemeForEmployerQuery,
  useLazyGetAssociatedSchemeForEmployerQuery
} = schemeApi;
