import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PrivatePlanByIdResponse,
  PrivatePlanList,
  SearchParam,
  DownloadCSVResponse,
  PrivatePlanStatusResponse,
  PrivatePlanStatus,
  CommentInfo,
  PlanComments,
  AddComment,
  PlanStatus,
  PayPeriod,
  PlanReason,
  UpdatePlanRequest,
  UpdatePrivatePlanResponse
} from "../../types/privatePlans";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const PRIVATE_API_REDUCER_KEY = "privatePlansApi";

export const privatePlanApi = createApi({
  reducerPath: PRIVATE_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["PrivatePlans", "ActiveProvider"],
  endpoints: (builder) => ({
    getPrivatePlanList: builder.query<PrivatePlanList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter, enabled} = args;
        const body = { pageNo, size, enabled };
        return {
          url: ApiConstant.PRIVATE_PLANS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["PrivatePlans"],
    }),
    getPrivatePlanById: builder.query<PrivatePlanByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PRIVATE_PLAN_BY_ID(id)}`,
          method: "GET",
        };
      },
    }),
    getPlanComments: builder.query<PlanComments, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PLAN_COMMENTS(id)}`,
          method: "GET",
        };
      },
    }),
    getPayPeriods: builder.query<PayPeriod, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PAY_PERIODS(id)}`,
          method: "POST",
        };
      },
    }),
    getPlanStatus: builder.query<PlanStatus, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PLAN_STATUS}`,
          method: "GET",
        };
      },
    }),
    getPlanType: builder.query<PlanStatus, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PLAN_TYPE}`,
          method: "GET",
        };
      },
    }),
    getPlanReason: builder.query<PlanReason, {ReasonType: number}>({
      query: (body) => {
        return {
          url: `${ApiConstant.PLAN_REASONS}`,
          method: "POST",
          body,
        };
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, { type: string; enabled: boolean }>({
      query: ({ type, enabled }) => {
        return {
          url: ApiConstant.DOWNLOAD_PRIVATE_PLAN_CSV(type),
          method: "POST",
          body: { enabled },
        };
      },
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.CREATE_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
          data: { error: string; message: string };
          status: number;
      }): string => response.data.error,
    }), 
    changePrivatePlanStatus: builder.mutation<PrivatePlanStatusResponse, PrivatePlanStatus>({
      query: (body: PrivatePlanStatus) => {
        const { planIds, action } = body;
        return {
          url: ApiConstant.CHANGE_PRIVATE_PLAN_STATUS,
          method: "PATCH",
          body: { planIds, action },
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }), 
    updatePrivatePlan: builder.mutation<UpdatePrivatePlanResponse, UpdatePlanRequest>({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_PRIVATE_PLAN(ID),
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),    
  }),
});

export const {
  useGetPrivatePlanListQuery,
  useGetPrivatePlanByIdQuery,
  useGetPayPeriodsQuery,
  useGetPlanTypeQuery,
  useGetPlanReasonQuery,
  useLazyGetPlanReasonQuery,
  useGetPlanStatusQuery,
  useGetPlanCommentsQuery,
  useAddCommentMutation,
  useLazyDownloadCSVQuery,
  useChangePrivatePlanStatusMutation,
  useUpdatePrivatePlanMutation,
} = privatePlanApi;
